import * as React from "react";

type BannerType = {
  sliderImage: string,
  desc: string,
  learnMoreLnk: string
}

function Banner(props: BannerType) {

  // const elementsWithBr = props.desc.map((fruit, index) => (
  //   <React.Fragment key={index}>
  //     {fruit}
  //     {index !== props.desc.length - 1 && <br />}
  //   </React.Fragment>
  // ));

  return (
    <div className="relative flex flex-col justify-center items-center  overflow-hidden   h-[500px]">
      <img
        loading="lazy"
        srcSet={props.sliderImage}
        alt="banner"
        className="absolute object-cover size-full"
      />
      <div className="absolute flex flex-col items-center justify-center text-white ">
        <div className="md:mt-2.5ng lg:text-5xl md:text-4xl  font-bold leading-10 text-3xl text-center text-balance w-5/12">
          {props.desc}
        </div>
        <div className="justify-center px-5 py-2 mt-8 text-lg font-medium bg-red-700 rounded-md cursor-pointer w-fit ">
          Learn More
        </div>
      </div>
    </div>
  );
}


export default Banner;