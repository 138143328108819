import BottumFooter from "../components/bottom_footer";
import MiddleFooter from "../components/sections/middle_footer";
import TopFooter from "../components/sections/top_footer";
import TopNav from "../components/sections/top_nav";


export default function OfficesPage() {
    return (
        <div className="flex flex-col items-center font-sans ">
            <div className="w-full">
                <TopNav />
            </div>
            <div className="relative">
                <img className="" src="images/office.png" alt="banner" />
                <div className="absolute font-sans text-6xl font-black text-white bottom-36 left-80">
                    Our Locations
                </div>
                <div className="absolute font-sans font-black text-white bottom-36 right-80">
                    Addis Ababa | Ankara
                </div>
            </div>
            <div className="grid grid-cols-2">
                <div>
                    <div className="relative w-full h-full">
                        <img className="items-stretch w-full h-full" src="images/city.png" alt="banner" />
                        <div className="absolute font-sans text-2xl lg:text-6xl font-black text-center text-white top-[40%] left-[30%] ">
                            Addis Ababa

                        </div>

                    </div>

                </div>
                <div>
                    <div className="relative w-full h-full">
                        <img className="items-stretch w-full h-full" src="images/city.png" alt="banner" />
                        <div className="absolute font-sans text-2xl lg:text-6xl font-black text-center text-white top-[40%] left-[30%] ">
                            Ankara
                        </div>

                    </div>

                </div>


            </div>


            <TopFooter />
            <MiddleFooter />
            <BottumFooter />

        </div>
    )
}