import { useState } from "react";
import TopNav from "../components/sections/top_nav";
import BottumFooter from "../components/bottom_footer";
import MiddleFooter from "../components/sections/middle_footer";
import TopFooter from "../components/sections/top_footer";

export default function OurLeadersPage() {

    const [activeButtonv, setActiveButtonv] = useState<string>(''); // State to track the active button

    const togglev = (buttonName: string) => {

        setActiveButtonv(activeButtonv === buttonName ? '' : buttonName);
    };

    return (
        <div className="flex flex-col items-center font-sans ">
            <div className="w-full">
                <TopNav />
            </div>
            <div className="relative mb-16">
                <img className="" src="images/abg.png" alt="banner" />
                <div className="absolute flex flex-col font-sans text-6xl font-black text-white bottom-36 left-80">
                    <i>Our Leaders</i>
                    <i>
                        & Experts
                    </i>
                </div>
            </div>
            <div className="flex flex-col justify-center w-8/12 gap-7">
                <div className="text-4xl font-bold">
                    Setting high standards and empowering the workforce start with our leadership.
                </div>
                <div className="flex flex-col gap-4 mb-16 text-lg ">
                    <i>
                        Forward-looking and dedicated, Koketi Ethiopia’s leadership comprises industry experts who bring decades of experience to our firm. They cultivate our strong company culture, inspire the team to reach greater heights, and diligently deliver expert asset solutions and investment opportunities. With local expertise, regional connection and sector specialisation, our leadership helps real estate owners, intenders and investors to make informed decisions, gauge growth, reduce risk, and improve overall success.
                    </i>
                    <i>
                        Get to know Koketi Ethiopia’s leadership and learn more about the individuals who drive our vision.
                    </i>
                </div>
            </div>
            <div className="flex flex-col items-center w-full py-20 font-sans bg-stone-100" >

                <div className="grid w-8/12 grid-cols-3 gap-16">
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>
                    <div className="flex flex-col items-center gap-4 p-10 bg-white rounded-lg">
                        <div className="rounded-full w-60 h-60 overflow-clip">
                            <img src="images/ceo.jpg" alt="" />
                        </div>
                        <div className="text-2xl font-bold">
                            Eng. Yonas Fantahun
                        </div>
                        <div className="text-base font-light">
                            Founder and Chief Executive Officer
                        </div>
                        <div className="text-2xl font-bold">
                            In
                        </div>
                        <img className="w-24 h-20" src="images/koket.jpg" alt="" />
                    </div>



                </div>




            </div>

            <TopFooter />
            <MiddleFooter />
            <BottumFooter />

        </div>
    )

}