import React from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PropertyEvaluation from './pages/services/PropertyEvaluation';
import ProjectManagmentAndCostConsultancy from './pages/services/ProjectManagmentAndCostCons';
import ProjectMarketing from './pages/services/ProjectMarketing';
import SnaggingAndInspection from './pages/services/Snagging-inspection';
import StrategicConsulting from './pages/services/StrategicConsulting';
import SalesAndLeasingBrokage from './pages/services/SalesAndLeasingBrokage';
import InvestMentAdvisory from './pages/services/InvestmentAdvisory';
import BuildingAndFacilityConsultancy from './pages/services/BuildingAndFacilityConsultancy';
import PropertyManagment from './pages/services/PropertyManagment';
import IndustrialConsultancy from './pages/services/IndustrialConsultancy';
import AboutUsPage from './pages/AboutUsPage';
import OurLeadersPage from './pages/OurLeaders';
import OfficesPage from './pages/OfficesPage';
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/property-evaluation',
    element: <PropertyEvaluation />
  },
  {
    path: '/project-managment-and-cost-consultancy',
    element: <ProjectManagmentAndCostConsultancy />
  },
  {
    path: '/project-marketing',
    element: <ProjectMarketing />
  },
  {
    path: '/inspection-snagging',
    element: <SnaggingAndInspection />
  },
  {
    path: '/property-strategic-consultancy',
    element: <StrategicConsulting />
  },
  {
    path: '/sales-and-leasing-brokerage',
    element: <SalesAndLeasingBrokage />
  },
  {
    path: '/property-investment-advisory',
    element: <InvestMentAdvisory />
  },
  {
    path: '/sectors-we-serve',
    element: <SnaggingAndInspection />
  },
  {
    path: '/building-and-facilities-consultancy',
    element: <BuildingAndFacilityConsultancy />
  },
  {
    path: '/property-management',
    element: <PropertyManagment />
  }
  ,
  {
    path: '/industrial-consultancy',
    element: <IndustrialConsultancy />
  },
  {
    path: '/about-us',
    element: <AboutUsPage />
  },
  {
    path: '/our-leaders',
    element: <OurLeadersPage />
  },
  {
    path: '/offices',
    element: <OfficesPage />
  }

])
function App() {

  return (
    <RouterProvider router={router} />
  )

}

export default App;
