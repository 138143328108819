/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import NewsCard from "../NewsCard";
import { FaAngleRight } from 'react-icons/fa'

function Vlogs() {
  return (
    <div className="flex flex-col justify-center">
      <div className="flex gap-5 justify-between items-start self-center pb-8 mt-16 w-full font-bold max-w-[1242px] max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
        <div className="text-4xl leading-10 text-black">Recent Vlogs</div>
        <div className="flex gap-0 justify-between items-center px-0.5 pt-px pb-0.5 mt-2 text-sm leading-6 text-red-700 rounded cursor-pointer hover:">
          <div className="">VIEW ALL VLOGS</div>
          <FaAngleRight />
        </div>
      </div>
      <div className="justify-center self-center w-full max-w-[1242px] max-md:max-w-full">
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 md:grid-cols-2">
          <NewsCard image={"images/news/n1.webp"} tags={['Popular', 'most liked']} date={"September 15, 2023"} desc={"Potential of Drones in Real Estate | Episode 2"} />
          <NewsCard image={"images/news/n3.webp"} tags={['Featured']} date={"September 8, 2023"} desc={" Ethiopia Real Estate Market Recap | Episode 1"} />
          <NewsCard image={"images/news/n2.webp"} tags={['Popular']} date={"September 19, 2022"} desc={"Importance and Impact of ESG in Real Estate investment"} />
          <NewsCard image={"images/news/n3.webp"} tags={['Popular']} date={"September 19, 2022"} desc={"Importance and Impact of ESG in Real Estate investment"} />
          <NewsCard image={"images/news/n1.webp"} tags={['Popular', 'most liked']} date={"September 15, 2023"} desc={"Potential of Drones in Real Estate | Episode 2"} />
          <NewsCard image={"images/news/n3.webp"} tags={['Featured']} date={"September 8, 2023"} desc={" Ethiopia Real Estate Market Recap | Episode 1"} />
          <NewsCard image={"images/news/n2.webp"} tags={['Popular']} date={"September 19, 2022"} desc={"Importance and Impact of ESG in Real Estate investment"} />
          <NewsCard image={"images/news/n3.webp"} tags={['Popular']} date={"September 19, 2022"} desc={"Importance and Impact of ESG in Real Estate investment"} />
        </div>
      </div>
    </div>
  );
}

export default Vlogs;
