import * as React from "react";

function BottumFooter() {
  return (
    <div className="flex flex-col w-full px-16 pb-5 text-sm font-light leading-5 tracking-wide pt-9 bg-neutral-600 text-stone-300 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-0 justify-center mx-10 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
        <div className="flex flex-col py-px max-md:max-w-full">
          <div className="max-md:max-w-full">
            Home | About Us | FAQs | Insights | Vlogs |  Careers | Contact Us
          </div>
          <div className="mt-3.5 max-md:max-w-full">
            © 2024 KOKETI Consulting P.L.C. | All Rights
            Reserved.
          </div>
        </div>
        <div className="flex flex-col items-end py-px pl-20 text-right max-md:max-w-full">
          <div>Office 502, Addis Ababa Ethiopia</div>
          <div className="mt-3.5">
            Terms & Conditions • Privacy and Cookies Policy • Sitemap
          </div>
        </div>
      </div>
    </div>
  );
}


export default BottumFooter;