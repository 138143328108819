import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function IndustrialConsultancy() {
    const services = [
        { title: "MEP Services Supervision" },
        { title: "Facilities Management Supervision" },
        { title: "Soft Services Supervision" },

    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Industrial', 'Consultancy']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Best Industrial Consulting Company in Ethiopia
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                Koketi is one of the leading industrial consultancy companies in Ethiopia, with expertise in asset valuation, verification, disposal, and more. We offer custom industrial consultancy solutions to all the major business sectors in Ethiopia to ensure business growth and productivity.
                                            </p>
                                            <p>
                                                We have a team of RICS-certified experts who specialise in industrial consultancy for sectors including agriculture, education, aviation, transport, mining, etc.
                                            </p>
                                            <p>
                                                Our goal is to help industry owners streamline their asset management and valuation process to stay on top of the production and delivery processes. Our valuation and advisory services span all the assets you have deployed in your industry.
                                            </p>


                                        </div>

                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <div className="flex justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b3fa23f343168a56677f28e13a93b062a292fa8c2cc7468956b6bf57c1dd16b8?apiKey=c671582bc9254df89ef0ab1440b5550b&"
                                                    className="shrink-0 my-auto aspect-square w-[18px]"
                                                />
                                                <div>Call</div>
                                            </div>
                                            <div className="flex justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950">
                                                <img
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/be0d7b2aafa56622e934a2743c0e7ee4bae45e5d5d032ddf89b9f68a25736d4b?apiKey=c671582bc9254df89ef0ab1440b5550b&"
                                                    className="shrink-0 my-auto aspect-square w-[18px]"
                                                />
                                                <div>Email</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                Expert Industrial Consultancy Services
                            </div>
                            <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                <p>
                                    Koketi’s Industrial Consultancy division delivers quality services across the Middle East and North Africa. Our Industrial Consultancy team provides well-defined Valuation/Cost management And solutions services through:
                                </p>

                            </div>
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">

                                <div className="flex flex-col mt-10">
                                    <div className="mb-5 text-2xl font-bold">Competence</div>
                                    <div className="text-lg">
                                        Our team has specialist and technical knowledge of all asset types across the industrial sectors to provide accurate services.
                                    </div>
                                </div>
                                <div className="flex flex-col mt-10">
                                    <div className="mb-5 text-2xl font-bold ">Experience</div>
                                    <div className="text-lg">
                                        Our team has the combined technical and practical industry experience to undertake reliable and accurate services across all industry sectors.
                                    </div>
                                </div>
                                <div className="flex flex-col mt-10 lg:items-center">
                                    <div className="mb-5 text-2xl font-bold">Excellence</div>
                                    <div className="text-lg">
                                        Koketi Industrial Consultancy Division endeavors professional and in-house training to deliver excellent, accurate, and accountable advice to our clients. Above all, Koketi Industrial Consultancy Division is here to deliver a full-fledged solutions package in line with international standards and best practices.
                                    </div>
                                </div>
                            </div>
                            <div className="hidden lg:block">
                                <ServiceListSection />
                            </div>

                            <div className="flex flex-col items-center justify-center gap-7 lg:hidden">
                                <div className="w-full px-5 py-8 rounded-lg shadow-lg">
                                    <div className="flex justify-center ">
                                        <div className="text-2xl font-bold text-center">Service</div>

                                    </div>
                                </div>

                                {
                                    services.map((service, index) => (
                                        <div className="w-full px-5 py-8 rounded-lg shadow-2xl">
                                            <div className="flex justify-center">
                                                <div>{service.title}</div>
                                                <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0 mb-7">
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    RICS-Compliance Consultancy Experts
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        We boast of an outstanding RICS-compliant team with an expertise in offering an array of valuation and advisory services across a broad spectrum of equipment, machinery, plant, and business assets. We are here to help you receive a detailed report outlining every single aspect covered under the inspection and valuation.
                                    </p>


                                </div>

                            </div>
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0 mb-7">
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Industries We Serve
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        Koketi Industrial Consultancy staff offer in-depth knowledge and experience of all asset categories across all industry sectors namely:
                                    </p>


                                </div>
                                <div className="pl-5 mt-4">
                                    <ul className="grid grid-cols-1 font-sans text-lg list-disc lg:grid-cols-2 gap-y-2 list">
                                        <li>Agriculture</li>
                                        <li>Aviation</li>
                                        <li>Food & Beverage</li>
                                        <li>Transport</li>
                                        <li>Plastic & Packaging</li>
                                        <li>Telecommunication</li>
                                        <li>Oil & Gas</li>
                                        <li>Paper & Printing</li>
                                        <li>Construction & Engineering</li>
                                        <li>Mining</li>
                                    </ul>
                                </div>
                                <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                    Advantages of Hiring Industrial Consultancy in
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        Hiring an industrial consultancy firm helps industry owners maximise the use of their assets and resources. Industrial consultants are aware of market regulations, and they offer expert advice based on their experience working in the industry. Industrial consultancy in Ethiopia helps business owners manage their assets and learn about their value, without any hassle. The insights gathered through consultancy add value to the industry operations by offering ways to optimise asset usage.
                                    </p>
                                    <p>
                                        Koketi has a team of industrial consultancy experts with 14+ years of industry experience. Our team brings with us over a decade of specialised experience in managing technical and management aspects of many different asset types.
                                    </p>
                                    <p>
                                        Our services range from doing asset valuations to asset verifications, and more. We have a robust yet flexible valuation and verification approach that we use to identify asset types in the industry for easier tracking and management. Being a market leader in providing industrial consultancy services, Koketi takes pride in being the top-rated choice of all major industries in Ethiopia.
                                    </p>

                                </div>
                                <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                    Get In Touch with Us for Professional Industrial Consultancy Services
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        Our RICS-certified team at Koketi understands the importance of asset management in managing industry operations Keeping that in mind we offer expert advice tailored to the needs of each specific industry. Our services are based on the latest industrial standards. We do a thorough inspection of all asset types and classes to ensure that you keep getting the best performance under all circumstances.
                                    </p>

                                    <p>
                                        Contact Koketi right now to get a free quote for our industrial consultancy services in Ethiopia.
                                    </p>

                                </div>

                            </div>





                        </div>
                    </div>






                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default IndustrialConsultancy;