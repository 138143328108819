import React from "react";

type Props = {
    image: string,
    desc: string,
    tags: Array<string>,
    date: string
}
function NewsCard(props: Props) {

    const tags = props.tags.map((tag, index) => (
        <React.Fragment key={index}>
            <div className="justify-center self-start px-2.5 py-0.5 mr-3 text-sm leading-6 text-white whitespace-nowrap bg-red-700 rounded-md">
                {tag}
            </div>
        </React.Fragment>
    ));
    return (
        <div className="flex flex-col max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch w-full pb-6 font-light bg-white bg-opacity-0 shadow-2xl grow rounded-xl max-md:mt-8">
                <div className="overflow-clip">
                    <img
                        loading="lazy"
                        src={props.image}
                        alt="news"
                        className="w-full aspect-[1.79] transform hover:scale-125 ease-in duration-100"
                    />
                </div>
                <div className="flex flex-col px-6 pt-4 pb-6 cursor-pointer max-md:px-5 hover:">
                    <div className="flex justify-start">
                        {tags}
                    </div>
                    <div className="mt-2.5 text-base font-bold leading-6 text-black max-md:mr-2.5">
                        {props.desc}
                    </div>
                    <div className="mt-4 text-xs leading-5 text-neutral-500">
                        {props.date}
                    </div>
                </div>
            </div>
        </div>)
}

export default NewsCard;