import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function StrategicConsulting() {
    const services = [
        { title: "Structural & MEP Testing" },
        { title: "BOQ Services" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Property Snagging and Inspections" },
        { title: "Survey & Audits" },
        { title: "Building Defect Investigation", },
        { title: "Measured Surveys", },
        { title: "QA/QC Inspection and Audits" },
        { title: "Building Condition Surveys & Audits – Civil, MEP, Structural & Facade" },
        { title: "Witnessing of Testing & Commissioning" },
        { title: "Energy Audits" },
        { title: "Energy Audit ASHRAE Level 1 & 2" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Related Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Strategic', 'Consultancy']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Best Strategic Consulting Firm in Ethiopia
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                Koketi is a leading strategic consulting firm in Ethiopia with 14+ years of industry experience. We offer expert strategic consulting services to developers, property, owners, and occupiers to maximize profit margins. Our strategic consulting firm specializes in strategy development for construction projects, real estate investments, and property inspections.
                                            </p>
                                            <p>
                                                Our strategic consulting firm specializes in strategy development for construction projects, real estate investments, real estate management consulting, and property inspections. We have a team of RICS-certified professionals and financial experts who handle the strategic aspects of property management for you to ensure success for every real estate project.
                                            </p>
                                            <p>
                                                Being closely integrated into Ethiopia’s real estate market for over a decade, Koketi has developed a knack for handling complicated strategy development and execution processes.
                                            </p>

                                        </div>



                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@koketi.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    What is Strategic Consultancy in Real Estate?
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        Strategic consultancy in real estate is about finding the right development and financial strategy for the real estate while keeping the best interests of the client at heart. A strategic consulting firm represents the client’s interests and comes up with proven strategies to ensure that the real estate investment or development process goes as smoothly as possible.
                                    </p>
                                    <p>
                                        Koketi is a RERA-regulated strategic consulting firm in Ethiopia with extensive experience with Ethiopia’s real estate market. We can help you come up with the right development, investment, and implementation strategies for your real estate decisions in record time.
                                    </p>

                                </div>
                                <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Benefits of Strategic Consultancy Services for Real Estate Investors
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        When it comes to buying, selling, or developing real estate, the right strategy can make all the difference. Strategy consulting firms in Ethiopia help property owners and developers with their investment decisions and develop strategies to ensure the best long-term gains for their clients.
                                    </p>
                                    <p>
                                        Strategic consulting firms offer services ranging from market research to feasibility studies, and more. They conduct due diligence on your behalf to ensure that your investment or development project is complete without any kind of problems.
                                    </p>
                                    <p>
                                        Strategic consulting firms have the experience and the resources to ensure the success of every real estate project. The strategic consulting services help you through each phase of strategy development and execution to ensure that you get the deliverables right on time.
                                    </p>
                                    <p>
                                        Koketi is a trusted strategic consulting Firm serving clients in Ethiopia. We offer strategic consultancy to our clients with their property investment and development decisions with the goal to maximise long-term profit margins for our clients.
                                    </p>

                                </div>
                                <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Professional Strategic Consulting Services
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        At Koketi, strategic consultancy offers real estate, infrastructure and urban economic consulting services to developers, owners, occupiers, institutional investors and government agencies across the Middle East and Africa. It combines people, services, and knowledge to deliver innovative, customised, quality-oriented, and cost-effective solutions.
                                    </p>
                                    <p>
                                        A seasoned strategic consulting team with the capabilities of providing sound construction, financial, planning, and development advice plays an integral part in delivering professional services. In addition, our unparalleled experience adds extensive value throughout the decision-making process and implementation life cycle of any real estate-oriented project.
                                    </p>

                                </div>


                            </div>
                        </div>
                    </div>


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default StrategicConsulting;