import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function PropertyManagment() {
    const services = [
        { title: "MEP Services Supervision" },
        { title: "Facilities Management Supervision" },
        { title: "Soft Services Supervision" },

    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Property', 'Management']} link={""} />
                    </div>

                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                Best Property Management Company in Ethiopia
                            </div>
                            <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                <p>
                                    Koketi is a comprehensive property management company in Ethiopia with expertise in property management services, FM services supervision, MEP services supervision, and soft services supervision.
                                </p>
                                <p>
                                    We have a team of trained professionals who handle property management processes. We create custom real estate management solutions for property owners to streamline management tasks.
                                </p>
                                <p>
                                    Our goal is to take the management pressure off the shoulders of property owners by offering holistic and customer-centric property management solutions. Koketi’s management experts offer recommendations for the optimal use of the property resources while increasing the return on your investment.
                                </p>

                            </div>
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0 mb-7">
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Expert Property Management in Addis Ababa, Bahir-dar, Adama and many regional states
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        The Koketi Property Management division has a combined global experience of over 100 years in Europe, North Africa, and the Middle East. We strongly understand local rules and regulations in the region, and we fully understand landlord and tenant requirements.
                                    </p>
                                    <p>
                                        We see property management as the art of protecting the investment cycle of our client’s assets. Therefore, we ensure that their portfolios perform at maximum capacity.
                                    </p>


                                </div>

                            </div>
                            <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                <div className="font-sans text-4xl font-bold">
                                    Reasons to Hire a Property Valuation Company in Ethiopia
                                </div>
                                <div className="text-lg">
                                    Independent property valuation may come off as an unnecessary expense. But this is far from the truth. Property valuation is not only necessary, but it can also prove to be quite important for you in maximising the return on your investment when buying or selling the property.
                                </div>
                                <div className="text-2xl font-bold">
                                    Benefits for Buyers
                                </div>
                                <div className="text-lg">
                                    Property valuation helps the buyer make an informed decision before buying a property. A property valuation involves a thorough inspection of the real estate. The in-depth inspection often leads to finding potential points that might lead to getting a better deal on the property. Koketi’s property consultant in Ethiopia services are ideal for buyers who want to buy a property that gives them the best value for their money.
                                </div>
                                <div className="text-2xl font-bold">
                                    Benefits for Sellers
                                </div>
                                <div className="text-lg">
                                    Sellers need to have a clear idea about the estimated value of their property as per the market trends. Setting a value that is way higher or way lower than the market trends can lead to a delay in making the sale. Koketi offers professional property valuation services that help sellers get a market-accurate estimate for the worth of their property. You can use the information provided by Koketi to maximise your return on investment.
                                </div>
                                <div className="text-2xl font-bold">
                                    Trusted Real Estate Valuation in Ethiopia
                                </div>
                                <div className="text-lg">
                                    Being an industry leader in the Ethiopia property valuation market, we value honesty and transparency. This is what inspires us to go out of our way to offer you the best real estate valuation services that you can use to make the right decisions with your property transaction process.


                                </div>
                                <div className="text-lg">Get in touch with Koketi right now to get an accurate property valuation for your commercial or residential property.</div>
                            </div>
                            <div className="hidden lg:block">
                                <ServiceListSection />
                            </div>

                            <div className="flex flex-col items-center justify-center gap-7 lg:hidden">
                                <div className="w-full px-5 py-8 rounded-lg shadow-lg">
                                    <div className="flex justify-center ">
                                        <div className="text-2xl font-bold text-center">Service</div>

                                    </div>
                                </div>

                                {
                                    services.map((service, index) => (
                                        <div className="w-full px-5 py-8 rounded-lg shadow-2xl">
                                            <div className="flex justify-center">
                                                <div>{service.title}</div>
                                                <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>



                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center hidden w-full px-16 font-sans max-md:px-5 max-md:max-w-full lg:block">
                        <div className="justify-center w-full max-w-full px-6 py-16 max-md:px-5">
                            <div className="px-10 pb-8 text-4xl font-bold text-center">What makes us different?</div>

                            <div className="grid grid-cols-2 gap-x-20">
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Energy Saving Solutions</div>
                                    <div className="text-lg">
                                        We offer a full range of energy-efficient solutions that focus on the key elements of a building, such as HVAC, controls & automation, lighting, and pumps and motors. Our ultimate goals are to drive down costs, reduce the environmental impact, and meet the unique needs of our clients.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma1.webp" alt="" />
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma2.webp" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">CRM & Use of Technology</div>
                                    <div className="text-lg">
                                        We use state-of-the-art Client Relationship Management software to control all aspects of the asset, whether for leasing, marketing, booking, logging maintenance requests or even financial management.
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Owners & Tenants Portal</div>
                                    <div className="text-lg">
                                        We provide our clients with an online platform and mobile apps allowing owners to access reports on demand and tenants to register maintenance requests through their dedicated portal.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma3.webp" alt="" />
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma5.webp" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Monthly Reports</div>
                                    <div className="text-lg">
                                        We issue monthly reports highlighting preventive maintenance activities, corrective work orders, and ongoing and completed tasks. In addition, the information includes financial data on material and labour costs along with the monthly invoices.
                                    </div>

                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">FM Supervision</div>
                                    <div className="text-lg">
                                        We supervise facility management of operation, corrective and preventive maintenance work for residential, commercial and industrial facilities. Our facility management team has the knowledge, expertise, processes, and technologies to ensure that facility management meets international standards.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma4.webp" alt="" />
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full lg:hidden">
                        <div className="justify-center w-full max-w-full py-16 max-md:px-5">
                            <div className="px-10 pb-8 text-4xl font-bold text-center">What makes us different?</div>

                            <div className="flex flex-col ">
                                <div className="flex flex-col justify-center mb-5 mt-7">
                                    <div className="pb-8 text-2xl font-bold">Energy Saving Solutions</div>
                                    <div className="text-lg">
                                        We offer a full range of energy-efficient solutions that focus on the key elements of a building, such as HVAC, controls & automation, lighting, and pumps and motors. Our ultimate goals are to drive down costs, reduce the environmental impact, and meet the unique needs of our clients.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma1.webp" alt="" />
                                </div>

                                <div className="flex flex-col justify-center mb-5 mt-7">
                                    <div className="pb-8 text-2xl font-bold">CRM & Use of Technology</div>
                                    <div className="text-lg">
                                        We use state-of-the-art Client Relationship Management software to control all aspects of the asset, whether for leasing, marketing, booking, logging maintenance requests or even financial management.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma2.webp" alt="" />
                                </div>

                                <div className="flex flex-col justify-center mb-5 mt-7">
                                    <div className="pb-8 text-2xl font-bold">Owners & Tenants Portal</div>
                                    <div className="text-lg">
                                        We provide our clients with an online platform and mobile apps allowing owners to access reports on demand and tenants to register maintenance requests through their dedicated portal.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma3.webp" alt="" />
                                </div>

                                <div className="flex flex-col justify-center mb-5 mt-7">
                                    <div className="pb-8 text-2xl font-bold">Monthly Reports</div>
                                    <div className="text-lg">
                                        We issue monthly reports highlighting preventive maintenance activities, corrective work orders, and ongoing and completed tasks. In addition, the information includes financial data on material and labour costs along with the monthly invoices.
                                    </div>

                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma5.webp" alt="" />
                                </div>
                                <div className="flex flex-col justify-center mb-5 mt-7">
                                    <div className="pb-8 text-2xl font-bold">FM Supervision</div>
                                    <div className="text-lg">
                                        We supervise facility management of operation, corrective and preventive maintenance work for residential, commercial and industrial facilities. Our facility management team has the knowledge, expertise, processes, and technologies to ensure that facility management meets international standards.
                                    </div>
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/pma4.webp" alt="" />
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">

                            <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                <div className="font-sans text-4xl font-bold">
                                    Benefits of Using a Property Management Company
                                </div>
                                <div className="text-2xl font-bold">
                                    Tenants Screening
                                </div>
                                <div className="text-lg">
                                    Koketi offers tenant screening services to ensure that you get the right tenants for your property. Having tenants who are likely to cause problems for you in the future can prove to be quite counterproductive. Koketi has a strict screening process that every tenant must go through before they can get your place for rent.
                                </div>
                                <div className="text-2xl font-bold">
                                    Rentals Marketing
                                </div>
                                <div className="text-lg">
                                    Our property managers are skilled at marketing the place. We come up with effective rental marketing strategies that help fill up the place much faster. We use local market research to find a competitive price for the rent while keeping your best interests in terms of profit margins at heart.
                                </div>
                                <div className="text-2xl font-bold">
                                    Increased Occupancy Rates
                                </div>
                                <div className="text-lg">
                                    The goal of our property consultancy services is to enhance the occupancy rates for your property. We do that through strategic rental marketing and by supervising the services provided by the property. We supervise everything from the tenant agreements to facilities as well as the MEP systems in a building to maximise efficiency.
                                </div>
                                <div className="text-2xl font-bold">
                                    Rent Collection
                                </div>
                                <div className="text-lg">
                                    Rent collection is a challenging endeavour that can often become quite difficult. We set up systems to ensure consistent rent collections across time. And if a tenant can’t seem to pay the rent on time, our managers deal with the situation on your behalf.
                                </div>
                                <div className="text-2xl font-bold">
                                    Peace of Mind
                                </div>
                                <div className="text-lg">
                                    The idea behind our real estate management services is to offer you a better way to manage your property, without all the hassle. We help minimise the real estate management headaches for you by taking over the management and supervision tasks on your behalf.
                                </div>
                            </div>



                        </div>
                    </div>





                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default PropertyManagment;