import { Transition, Dialog } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom"


const MobileMenu = () => {

    const [activeButton, setActiveButton] = useState<string>(''); // State to track the active button
    const [open, setOpen] = useState(false)
    const firstName = useRef(null)

    const toggleButton = (buttonName: string) => {
        setActiveButton(activeButton === buttonName ? '' : buttonName);
    };



    return (
        <div className="flex flex-col bg-stone-100 ">
            <div className="flex flex-col content-center justify-center gap-5 py-6 text-xl leading-6 text-black p-44 px-7">
                <Link to={'/'} className="flex justify-between gap-1 pr-5 whitespace-nowrap hover:text-red-700 hover:cursor-pointer hover:font-bold">
                    <div>
                        Home
                    </div>

                </Link>
                <div className="w-full">

                    <div className="flex flex-row justify-between gap-1 pr-5 whitespace-nowrap hover:text-red-700 hover:cursor-pointer hover:font-bold" onClick={() => toggleButton('service')}>
                        <div>Services</div>
                        <div>
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="angle-down"><path fill="#FF1414" d="M16.9,9.2c-0.4-0.4-1-0.4-1.4,0L12,12.7L8.5,9.2c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l4.2-4.2C17.3,10.2,17.3,9.6,16.9,9.2z"></path></svg>
                        </div>
                    </div>
                    <div className={activeButton === 'service' ? '' : 'hidden'}>
                        <div className="grid grid-cols-2 gap-3 pr-5">
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-evaluation"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Valuations</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>

                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/project-managment-and-cost-consultancy"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Project Management & Cost Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/project-marketing"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Project Marketing</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/inspection-snagging"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Snagging & Inspection</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-strategic-consultancy"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Strategic Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/sales-and-leasing-brokerage"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Sales & Leasing Brokerage</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-investment-advisory"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Investment Advisory</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={""} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Sectors We Serve</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/building-and-facilities-consultancy"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Building & Facilities Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-management"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Management</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/industrial-consultancy"} >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Industrial Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
                <button className="flex justify-between gap-1 pr-5 hover:text-red-700 hover:cursor-pointer hover:font-bold">
                    <div>Insight & Trends</div>
                    <div>
                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="angle-down"><path fill="#FF1414" d="M16.9,9.2c-0.4-0.4-1-0.4-1.4,0L12,12.7L8.5,9.2c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l4.2-4.2C17.3,10.2,17.3,9.6,16.9,9.2z"></path></svg>
                    </div>
                </button>
                <button className="flex justify-between gap-1 pr-5 hover:text-red-700 hover:cursor-pointer hover:font-bold" onClick={() => toggleButton('aboutus')}>
                    <div>About Us</div>
                    <div>
                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="angle-down"><path fill="#FF1414" d="M16.9,9.2c-0.4-0.4-1-0.4-1.4,0L12,12.7L8.5,9.2c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l4.2-4.2C17.3,10.2,17.3,9.6,16.9,9.2z"></path></svg>
                    </div>
                </button>
            </div><button className="justify-center px-5 py-2 my-auto text-lg font-medium leading-7 text-white bg-red-700 rounded-md" onClick={() => setOpen(true)}>
                Enquire Now
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => setOpen(false)} initialFocus={firstName} >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                        <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl ">
                                    <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                                        <div className="grid grid-cols-1 ">
                                            <div className="col-span-8 ">
                                                <form className="grid grid-cols-2">
                                                    <div className="col-span-2 mt-5 mr-5 text-3xl font-bold">Get in Touch</div>
                                                    <div className="col-span-2 mt-5 mr-5 text-lg">Please fill in the form below and we will contact you shortly.</div>
                                                    <input type="text" className="col-span-1 mt-5 mr-5" placeholder="First Name" ref={firstName} />
                                                    <input type="text" className="col-span-1 mt-5 mr-5" placeholder="Last Name" />
                                                    <input type="text" className="col-span-2 mt-5 mr-5" placeholder="Company Name *" />
                                                    <input type="text" className="col-span-1 mt-5 mr-5" placeholder="Mobile Number *" />
                                                    <input type="text" className="col-span-1 mt-5 mr-5" placeholder="Email Address" />
                                                    <textarea className="col-span-2 mt-5 mr-5" name="message" id="" placeholder="Message"></textarea>
                                                </form>

                                            </div>
                                            <div className="col-span-4 py-20 mx-10">
                                                <div className="flex flex-row mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-6 mr-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                    </svg>
                                                    <div className="text-sm">090000000000</div>
                                                </div>
                                                <div className="flex flex-row mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-6 mr-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                    </svg>
                                                    <div className="text-sm">info@koketi.com</div>
                                                </div>
                                                <div className="flex flex-row mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-6 mr-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                                    </svg>

                                                    <div className="text-sm">Office 502, Duki Building, Gabon Street,  Addis Ababa, Ethiopia.</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50">
                                        <button
                                            type="button"
                                            className="inline-flex justify-start w-full px-3 py-2 text-base font-normal text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 sm:m-5 sm:w-auto"
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            Submit Form
                                        </button>

                                    </div>
                                    <div className="absolute rounded-md shadow-sm top-5 right-5 hover:cursor-pointer" onClick={() => setOpen(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 hover:text-red-500 hover:cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}


export default MobileMenu;