import * as React from "react";


interface ServiceBannerDet {
    image: string
    title: Array<string>
    link: string

}



function ServiceBanner(props: ServiceBannerDet) {

    const titles = props.title.map((fruit, index) => (
        <React.Fragment key={index}>
            {fruit}
            {index !== props.title.length - 1 && <br />}
        </React.Fragment>
    ));
    return (
        <div className="flex overflow-hidden relative flex-col justify-center self-stretch w-full text-white min-h-[666px] max-md:max-w-full z-10">
            <img
                loading="lazy"
                src={props.image}
                className="absolute inset-0 object-cover size-full"
                alt="dd"
            />
            <div className="relative flex items-center justify-center w-full px-16 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 justify-between items-center px-6 py-20 max-w-full w-[1290px] max-md:flex-wrap max-md:px-5">
                    <div className="self-stretch my-auto text-6xl font-bold leading-[70px] max-md:text-4xl max-md:leading-[52px]">
                        {titles}
                    </div>
                    <form className="flex flex-col self-stretch w-full my-auto px-9 py-11 lg:w-6/12 rounded-xl bg-sky-950 bg-opacity-80 max-md:px-5 max-md:max-w-full">
                        <div className="text-3xl font-bold leading-9 max-md:max-w-full">
                            Enquire Now
                        </div>
                        <div className="mt-1.5 text-base leading-6 max-md:max-w-full">
                            Please fill in the form below and we will contact you shortly.
                        </div>
                        <div className="flex flex-col justify-between gap-4 px-px pb-5 mt-4 text-lg font-light lg:flex-row text-neutral-400 max-md:flex-wrap max-md:max-w-full">
                            <div className="flex flex-col items-start justify-between w-full gap-4 pt-px pl-px lg:pb-5 lg:flex-row">
                                <input className="items-start justify-center w-full px-4 py-3 bg-transparent border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="First Name" />
                                <input className="items-start justify-center w-full px-4 py-3 bg-transparent border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Last Name" />
                                <input className="items-start justify-center w-full px-4 py-3 bg-transparent border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Company" />
                            </div>

                        </div>
                        <div className="flex flex-col justify-between gap-4 px-px pt-px pb-5 text-lg font-light lg:flex-row text-neutral-400 max-md:flex-wrap max-md:max-w-full">
                            <input className="items-start justify-center w-full px-4 py-3 bg-transparent border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Mobile Number" />
                            <input className="items-start justify-center w-full px-4 py-3 bg-transparent border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Email Address" />

                        </div>
                        <textarea placeholder="Message" className="z-10 shrink-0 -mt-1.5 rounded-md border bg-transparent border-solid border-gray-200 border-opacity-80 h-[90px] max-md:max-w-full" />
                        <div className="justify-center self-start px-5 py-2.5 mt-10 text-base font-medium leading-6 text-center whitespace-nowrap bg-red-700 rounded-lg max-md:px-5 hover:cursor-pointer">
                            Submit
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ServiceBanner;