import * as React from "react";

function TopFooter() {
  return (
    <div className="flex flex-col w-full px-16 pt-8 mt-16 bg-sky-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="justify-center pb-3.5 mx-10 max-md:mr-2.5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[67%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch grow max-md:mt-10 max-md:max-w-full">
              <div className="justify-center pb-8 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow self-stretch pt-5 pb-2.5 text-base text-white max-md:mt-8">
                      <div className="text-xl font-bold leading-6">Meet Us</div>
                      <div className="mt-5 font-light leading-6 text-stone-300">
                        Office 502, Duki Building, Gabon
                        <br />
                        Street, Meskel flower, Ethiopia, Addis Ababa.
                      </div>
                      <div className="mt-4 leading-[167%]">Locate us</div>
                    </div>
                  </div>
                  <div className="flex flex-col w-6/12 ml-5 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col self-stretch pt-5 pb-4 text-base font-bold leading-6 max-md:mt-8">
                      <div className="text-xl leading-6 text-white">
                        Get in touch
                      </div>
                      <div className="mt-5 font-light text-zinc-300">
                        Feel free to call and discuss about your property
                      </div>
                      <div className="text-white">+251 93 029 4775</div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa1883e3898ea7dc81311c6535b4a3dc77da1239b8c90b9d52461ac0cd7313?apiKey=c671582bc9254df89ef0ab1440b5550b&"
                className="z-10 mt-0 w-full aspect-[4.17] max-md:max-w-full"
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch py-5 font-light text-white max-md:mt-10">
              <div className="text-xl font-bold leading-6">
                Request A Callback
              </div>
              <div className="flex items-start justify-between gap-4 pt-px pb-5 pl-px mt-5 text-lg text-neutral-400">
                <input className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="First Name" />
                <input className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Last Name" />

              </div>
              <div className="flex items-start justify-between gap-4 pb-5 pl-px text-lg text-neutral-400">
                <input className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Mobile Number" />

                <input className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Email Address" />

              </div>

              <div className="justify-center self-start px-5 py-2.5 mt-6 text-base font-medium leading-6 text-center whitespace-nowrap bg-red-700 rounded-lg border border-red-700 border-solid max-md:px-5 hover:cursor-pointer">
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFooter;
