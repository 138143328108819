import * as React from "react";

interface ServiceBoxProps {
    title: string;
}

const ServiceBox: React.FC<ServiceBoxProps> = ({ title }) => {
    return (
        <div className="flex flex-col justify-center p-6 bg-white shadow-2xl rounded-xl max-md:px-5">
            <div className="flex justify-between gap-0 bg-white rounded">
                <div>{title}</div>
                <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                </svg>

            </div>
        </div>
    );
};



export default ServiceBox;