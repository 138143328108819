import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ImageCard from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";


const ProjectList: React.FC = () => {
    const imageCardData = [
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/f69bde4b2b6f4f1b9d066276e6b7a6765c5d3fb5eb9f3cd73a2dd7a661071cd2?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "Misk Foundation",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/b1f3a463666916a2740344a13dd5199e15290d6b75f496e2d814d572aaa79251?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "RAKEZ",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/c667d5027efbf6e7c4e5041d4ca384c993add6e8fe8be254ec68a39a49c33cfd?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "Union Properties PJSC Dubai, UAE",
            subtitle: "Property Valuations | Strategic Consultancy",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8c666708948a09af18514a4281425d6fd26c8a12ff738b4cca13d43141262c3e?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "Al Ghurair Real Estate – Entire Portfolio",
            subtitle: "Property Valuations",
        },
    ];

    return (
        <div className="justify-center px-2.5 py-16 max-w-[1290px] max-md:pl-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
                    <div className="items-start self-stretch w-full px-8 pt-6 pb-20 text-4xl font-bold leading-8 text-white grow bg-sky-950 max-md:px-5 max-md:mt-10">
                        From Our <br /> Track Record
                    </div>
                </div>
                <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col px-4 grow max-md:mt-10 max-md:max-w-full">
                        <div className="max-md:max-w-full">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                {imageCardData.slice(0, 3).map((card, index) => (
                                    <div key={index} className={`flex flex-col ${index === 0 ? "w-[33%]" : "ml-5 w-[33%]"} max-md:ml-0 max-md:w-full`}>
                                        <ImageCard imageSrc={card.imageSrc} title={card.title} subtitle={card.subtitle} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-8 max-w-full text-center text-white bg-neutral-400 w-[253px]">
                            <ImageCard imageSrc={imageCardData[3].imageSrc} title={imageCardData[3].title} subtitle={imageCardData[3].subtitle} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function ProjectManagmentAndCostConsultancy() {
    const services = [
        { title: "Construction Consultancy" },
        { title: "Commercial Services" },
        { title: "MEP Services & Sustainability" },
        { title: "Cost to Completion Studies" },
        { title: "Project Management" },
        { title: "Construction Management" },
        { title: "Retail & Design Delivery", },
        { title: "Market Research Demand Forecasting", },
        { title: "BOQ Services" },
        { title: "Hospitality Valuation" },
        { title: "Design Management" },
        { title: "Fit-out Management" },
        { title: "Construction Project Management" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Project Managment &', 'Cost Consultancy']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Professional Project Management Company in Ethiopia
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>Koketi provides project management and cost consultancy services in accordance with rigorous CIOB and RICS standards and across multiple sectors. The cornerstones of our business are strategic planning and quality assurance, and these are underpinned by a team of highly qualified professionals.</p>
                                            <p>Koketi is one of the best construction project management companies in Ethiopia, UAE which provides project management, construction consulting, commercial property valuation, property consultant, and cost consultancy services in accordance with rigorous CIOB and RICS standards and across multiple sectors. The cornerstones of our business are strategic planning and quality assurance, and these are underpinned by a team of highly qualified professionals.</p>
                                            <p>Our project management experts specialize in creating robust project management solutions that minimize the financial and technical risks associated with the project. We simplify the project implementation process to make it easier for you to keep track of the project’s progress.</p>

                                        </div>
                                        <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                            Project Management and Cost Consultancy Company in Ethiopia and Regional states
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                Our project management and consultancy team delivers projects through effective planning, rigorous controls, and the accumulation of many years of experience. Koketi’s methodology reduces client risk and ensures cost, time, and quality objectives are achieved. Our robust systems and in-house procedures have been externally audited and are utilized throughout the life of the project in order that all project challenges and risks to the desired outcome are effectively addressed. We work with many different clients from developers to operators and contractors as part of an ‘integrated’ delivery team. In addition, we work closely with investors and real estate organizations to manage budgets, monitor financial outlay, and ensure all project objectives are achieved.
                                            </p>

                                        </div>


                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@koketi.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                    <div className="font-sans text-4xl font-bold">
                                        Why Choose Our Project Management & Construction Cost Consultancy Services?
                                    </div>

                                    <div className="text-2xl font-bold">
                                        14+ Years of Experience
                                    </div>
                                    <div className="text-lg">
                                        Equipped with over a decade of industry experience, Koketi has the tools, knowledge, and resources required to manage a project in the best way. We are an established name in Ethiopia’s real estate market with 14+ years of experience in providing out-of-box real estate development and project management solutions.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        RICS-certified Team
                                    </div>
                                    <div className="text-lg">
                                        Our RICS-certified experts understand the value that effective project management brings to the table. They use the latest project management tools, technologies, and strategies to ensure that you get the best out of your investment in a real estate project. Our team will give you an accurate idea about the construction cost for the project that you can use to manage your budget.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        10,000+ Projects Completed
                                    </div>
                                    <div className="text-lg">
                                        Our confidence in our services stems from the fact that we have worked on 10,000+ real estate projects over the past 14+ years. The extensive practical industry experience has enabled us to come up with designs and systems that cater to your specific business needs.


                                    </div>
                                    <div className="text-2xl font-bold">
                                        Experience with Rules & Regulations
                                    </div>
                                    <div className="text-lg">
                                        The qualitative and quantitative analysis of a construction process is just one side of the story. You also need to be aware of the project management rules and regulations established in Ethiopia. We offer guidance to our clients in terms of navigating the legal framework for project development in Ethiopia.


                                    </div>
                                    <div className="text-lg">Get in touch with Koketi right now to get an accurate property valuation for your commercial or residential property.</div>
                                </div>
                                <div className="flex flex-col max-w-full gap-10 pb-5 bg-stone-50">
                                    <div className="hidden lg:block">
                                        <ServiceListSection />
                                    </div>

                                    <div className="flex flex-col items-center justify-center gap-7 lg:hidden">
                                        <div className="w-full px-5 py-8 rounded-lg shadow-lg">
                                            <div className="flex justify-center ">
                                                <div className="text-2xl font-bold text-center">Service</div>

                                            </div>
                                        </div>

                                        {
                                            services.map((service, index) => (
                                                <div className="w-full px-5 py-8 rounded-lg shadow-2xl">
                                                    <div className="flex justify-center">
                                                        <div>{service.title}</div>
                                                        <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20">
                                    <div className="flex flex-col justify-center">
                                        <div className="pb-8 text-4xl font-bold">Our Focus</div>
                                        <div className="text-lg">
                                            Our main focus is understanding and meeting our clients’ needs and representing their best interests throughout; all while providing the highest return on their investment. It’s a formula that has helped us deliver some of the region’s most complex projects.
                                        </div>
                                    </div>
                                    <div >
                                        <img className="w-full h-full" src="images/services/pm1.webp" alt="" />
                                    </div>
                                    <div className="hidden lg:block">
                                        <img className="w-full h-full" src="images/services/pm2.webp" alt="" />
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <div className="pb-8 text-4xl font-bold">Collaboration & Technology</div>
                                        <div className="text-lg">
                                            Our team of experts make it a priority to remain up-to- date with the latest in project management technology, including in house software development. We are therefore able to provide accurate and reliable measured data from the outset for a broad range of project types. This keeps stakeholders fully engaged and informed and fosters a collaborative ‘Right First Time” approach to project delivery.
                                        </div>
                                    </div>
                                    <div className="lg:hidden">
                                        <img className="w-full h-full" src="images/services/pm2.webp" alt="" />
                                    </div>

                                    <div className="flex flex-col justify-center">
                                        <div className="pb-8 text-4xl font-bold">Trained Personnel</div>
                                        <div className="text-lg">
                                            Our staff have proven track records in the region and are trained in accordance with a number of industry-wide standards (including RICS, CIOB and IOSHH). This gives our clients the peace of mind that all of our staff work to a high level of competence and professionalism. Our success is fueled by their dedication, enthusiasm and extensive experience.
                                        </div>
                                    </div>
                                    <div>
                                        <img className="w-full h-full" src="images/services/pm3.webp" alt="" />
                                    </div>
                                    <div className="hidden lg:block">
                                        <img className="w-full h-full" src="images/services/pm4.webp" alt="" />
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <div className="pb-8 text-4xl font-bold">Project Cost & Evaluation</div>
                                        <div className="text-lg">
                                            Land Sterling will work collaboratively with our customers from the outset and provide substantiated data and information to enable them to make fully informed decisions. Effective management of project costs is a key component to a project’s success. Our clients are afforded full transparency to project expenditure; with close monitoring and detailed reporting. We will constantly assess the cost-effectiveness of the design, identifying alternative solutions and build-ability solutions to ultimately add value, reduce cost and mitigate risk.
                                        </div>
                                    </div>
                                    <div className="lg:hidden">
                                        <img className="w-full h-full" src="images/slider.jpg" alt="" />
                                    </div>

                                </div>

                                <div className="hidden bg-stone-50 lg:block">
                                    <div className="pb-8 text-4xl font-bold text-center">Project Workflow</div>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20 even:flex-col-reverse ">
                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/slider.jpg" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Preparation & Brief
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Agree Project Vision Concept & Objectives <br />
                                                Formulate Capital Outlay, Budgets and ROI<br />
                                                Prepare Employers Information Requirements<br />
                                                Formulate Brief & Basis of Design<br />
                                                Establish Project Controls & Protocols<br />
                                                Establish Risk Register<br />
                                                Establish Development Program<br />
                                                Review Life Cycle Costing & Operational Expenditure<br />
                                                Site Due Diligence & Engineering Surveys
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Shortlist Design Team & Issue Requests for Proposal <br />
                                                Prepare Appointment & Agree Scope of Deliverables<br />
                                                Review Design Risk Register<br />
                                                Formulate Brief & Basis of Design<br />
                                                Issue Staged Cost Plans & Prepare PTE / BoQ<br />
                                                Agree Procurement Route and Form of Contract<br />
                                                Implement and Manage Change Control Process<br />
                                                Value Engineering / Buildability / FM & Sustainability Reviews Prepare Permitting & NOC Matrix<br />
                                                Coordinate BIM Model
                                            </div>
                                        </div>

                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/slider.jpg" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Design
                                            </div>
                                        </div>
                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/slider.jpg" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Procurement
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Shortlist Contractors & Compile and Issue ER’s / Tenders Prepare Appointment & Agree Scope of Deliverables<br />
                                                Agree PTE – Manage PQ & TQ Process<br />
                                                Manage Tender Process/Analyse Tender Returns<br />
                                                Formulate and Agree KPI’s<br />
                                                Agree Cost Plan and Insurances<br />
                                                Agree GMP / Lump Sum / C+ (Value Engineer)<br />
                                                Negotiate and Agree Contract Terms<br />
                                                Appoint Contractor<br />
                                            </div>
                                        </div>

                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Agree Health & Safety Plan & HSE KPI’s / Method Statements<br />
                                                Cost Control / Change Management / VO’s & Claims<br />
                                                Manage Contractor Design & Shop Drawings<br />
                                                Manage Material Submittals<br />
                                                QAQC & Compliance Checks<br />
                                                Post Contract Quantity Surveying<br />
                                                Payment Reviews Earned Value<br />
                                                Manage Reporting & Dashboards / Critical Path Analysis –Interface Management & Coordination<br />
                                            </div>
                                        </div>
                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/slider.jpg" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Construction
                                            </div>
                                        </div>
                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/slider.jpg" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Handover
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Beneficial Occupancy / Soft Landing / Tenant Handbook<br />
                                                Cost Control / Change Management / VO’s & Claims<br />
                                                Fit-Out Process<br />
                                                FFE / OSE Installation & Logistic Planning<br />
                                                Handover Checklist / Snagging & Punch Lists<br />
                                                TOC / Final Account<br />
                                                O&M Manuals / Warranties<br />
                                                Back End Approvals / BCC – Claims Assessment and Management<br />
                                                Arbitration / Mediation / Litigation<br />
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                <div className="bg-stone-50 lg:hidden">
                                    <div className="pb-8 text-4xl font-bold text-center">Project Workflow</div>
                                    <div className="flex flex-col lg:grid-cols-2 gap-x-20 ">
                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/services/pmf1.webp" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Preparation & Brief
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Agree Project Vision Concept & Objectives <br />
                                                Formulate Capital Outlay, Budgets and ROI<br />
                                                Prepare Employers Information Requirements<br />
                                                Formulate Brief & Basis of Design<br />
                                                Establish Project Controls & Protocols<br />
                                                Establish Risk Register<br />
                                                Establish Development Program<br />
                                                Review Life Cycle Costing & Operational Expenditure<br />
                                                Site Due Diligence & Engineering Surveys
                                            </div>
                                        </div>
                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/services/pmf2.webp" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Procurement
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Shortlist Contractors & Compile and Issue ER’s / Tenders Prepare Appointment & Agree Scope of Deliverables<br />
                                                Agree PTE – Manage PQ & TQ Process<br />
                                                Manage Tender Process/Analyse Tender Returns<br />
                                                Formulate and Agree KPI’s<br />
                                                Agree Cost Plan and Insurances<br />
                                                Agree GMP / Lump Sum / C+ (Value Engineer)<br />
                                                Negotiate and Agree Contract Terms<br />
                                                Appoint Contractor<br />
                                            </div>
                                        </div>

                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/services/pmf3.webp" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Design
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Shortlist Design Team & Issue Requests for Proposal <br />
                                                Prepare Appointment & Agree Scope of Deliverables<br />
                                                Review Design Risk Register<br />
                                                Formulate Brief & Basis of Design<br />
                                                Issue Staged Cost Plans & Prepare PTE / BoQ<br />
                                                Agree Procurement Route and Form of Contract<br />
                                                Implement and Manage Change Control Process<br />
                                                Value Engineering / Buildability / FM & Sustainability Reviews Prepare Permitting & NOC Matrix<br />
                                                Coordinate BIM Model
                                            </div>
                                        </div>

                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/services/pmf4.webp" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Construction
                                            </div>
                                        </div>



                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Agree Health & Safety Plan & HSE KPI’s / Method Statements<br />
                                                Cost Control / Change Management / VO’s & Claims<br />
                                                Manage Contractor Design & Shop Drawings<br />
                                                Manage Material Submittals<br />
                                                QAQC & Compliance Checks<br />
                                                Post Contract Quantity Surveying<br />
                                                Payment Reviews Earned Value<br />
                                                Manage Reporting & Dashboards / Critical Path Analysis –Interface Management & Coordination<br />
                                            </div>
                                        </div>

                                        <div className="flex flex-col bg-white rounded rounded-lg shadow-lg overflow-clip gap-11 m-11 ">
                                            <img className="w-full h-9/12" src="images/services/pmf5.webp" alt="" />
                                            <div className="self-center text-3xl font-bold text-center pb-11">
                                                Handover
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="text-l">
                                                Beneficial Occupancy / Soft Landing / Tenant Handbook<br />
                                                Cost Control / Change Management / VO’s & Claims<br />
                                                Fit-Out Process<br />
                                                FFE / OSE Installation & Logistic Planning<br />
                                                Handover Checklist / Snagging & Punch Lists<br />
                                                TOC / Final Account<br />
                                                O&M Manuals / Warranties<br />
                                                Back End Approvals / BCC – Claims Assessment and Management<br />
                                                Arbitration / Mediation / Litigation<br />
                                            </div>
                                        </div>




                                    </div>
                                </div>


                                <ProjectList />



                            </div>
                        </div>
                    </div>


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default ProjectManagmentAndCostConsultancy;