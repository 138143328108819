/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestemonialCard from "../TestemonialCard";
function Testemonial() {

  const [isMobile, setIsMobile] = React.useState(Number)

  //choose the screen size 
  const handleResize = () => {
    setIsMobile(window.innerWidth)
  }

  // create an event listener
  React.useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  var settings = {
    dots: true,
    infinite: true,
    speed: 10,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

  };
  var toShowTwo = {
    slidesToShow: 2
  }

  var toShowThree = {
    slidesToShow: 3
  }

  var toShowOne = {
    slidesToShow: 1
  }



  return (

    <div className="flex flex-col justify-center p-10 mt-16 ">
      <div className=" flex mx-5 text-4xl  font-bold leading-10 text-black max-md:mr-2.5  justify-start">
        Testimonials
      </div>
      <div className="flex gap-1.5 items-center justify-center pb-8 mx-5 mt-8 mb-1 max-md:flex-wrap max-md:mr-2.5">

        <div className="flex flex-col self-stretch justify-center ">
          <div className="justify-center slider-container md:hidden">
            <Slider {...settings} {...toShowOne} className="flex max-w-xs ">

              <TestemonialCard name={"M Shawish"} noStars={5} testemony={"He covered the slightest details leaving no stone unturned and that really gave us peace of mind. Thank you Koketi and thank you Mohammad Ismail. I would recommend him any day."} />
              <TestemonialCard name={"Anjum Habib"} noStars={5} testemony={"So overall, Koketi deserves 7 stars for their service and I wholeheartedly recommend them to anyone wishing to have a Snagging Inspection Report undertaken."} />
              <TestemonialCard name={"M Shawish"} noStars={5} testemony={"He covered the slightest details leaving no stone unturned and that really gave us peace of mind. Thank you Koketi and thank you Mohammad Ismail. I would recommend him any day."} />
              <TestemonialCard name={"Anjum Habib"} noStars={5} testemony={"So overall, Koketi deserves 7 stars for their service and I wholeheartedly recommend them to anyone wishing to have a Snagging Inspection Report undertaken."} />
              <TestemonialCard name={"M Shawish"} noStars={5} testemony={"He covered the slightest details leaving no stone unturned and that really gave us peace of mind. Thank you Koketi and thank you Mohammad Ismail. I would recommend him any day."} />
              <TestemonialCard name={"Anjum Habib"} noStars={5} testemony={"So overall, Koketi deserves 7 stars for their service and I wholeheartedly recommend them to anyone wishing to have a Snagging Inspection Report undertaken."} />


            </Slider>
          </div>
          <div className="justify-center hidden slider-container md:block">
            <Slider {...settings} {...toShowThree} className="flex max-w-screen-lg ">

              <TestemonialCard name={"M Shawish"} noStars={5} testemony={"He covered the slightest details leaving no stone unturned and that really gave us peace of mind. Thank you Koketi and thank you Mohammad Ismail. I would recommend him any day."} />
              <TestemonialCard name={"Anjum Habib"} noStars={5} testemony={"So overall, Koketi deserves 7 stars for their service and I wholeheartedly recommend them to anyone wishing to have a Snagging Inspection Report undertaken."} />
              <TestemonialCard name={"M Shawish"} noStars={5} testemony={"He covered the slightest details leaving no stone unturned and that really gave us peace of mind. Thank you Koketi and thank you Mohammad Ismail. I would recommend him any day."} />
              <TestemonialCard name={"Anjum Habib"} noStars={5} testemony={"So overall, Koketi deserves 7 stars for their service and I wholeheartedly recommend them to anyone wishing to have a Snagging Inspection Report undertaken."} />
              <TestemonialCard name={"M Shawish"} noStars={5} testemony={"He covered the slightest details leaving no stone unturned and that really gave us peace of mind. Thank you Koketi and thank you Mohammad Ismail. I would recommend him any day."} />
              <TestemonialCard name={"Anjum Habib"} noStars={5} testemony={"So overall, Koketi deserves 7 stars for their service and I wholeheartedly recommend them to anyone wishing to have a Snagging Inspection Report undertaken."} />


            </Slider>
          </div>
        </div>
        {/* <div className="shrink-0 self-stretch py-5 pr-2.5 pl-1.5 my-auto rounded bg-white bg-opacity-80 h-[50px] w-[30px] justify-center" /> */}
      </div>
    </div>
  );
}


export default Testemonial;