"use client";
import * as React from "react";
import { useState } from "react";

function ServiceProviderMessage() {
    const [We, setWe] = useState(() => null);

    return (
        <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
            <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                            <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                Best Property Valuation Company in Ethiopia
                            </div>
                            <div className="mt-4 max-md:mr-2.5 max-md:max-w-full">
                                Koketi takes pride in offering best-in-class services of
                                real estate valuation in Ethiopia. Ever since our

                                inception in 2009, we have put in the best of our efforts to
                                ascertain that our clients receive asset valuation

                                services aimed at helping them make the best, most knowledgeable
                                decisions. We are experts at conducting

                                residential & commercial property valuation in Ethiopia.
                            </div>
                            <div className="mt-8 max-md:max-w-full">
                                Getting an accurate estimate of the market value of a property
                                is crucial for the buyer as well as the seller. It
                                helps determine how much your property is worth at the time of
                                valuation. The estimation helps the parties
                                involved get the best deal on their real estate transaction.
                            </div>
                            <div className="mt-8 max-md:max-w-full">
                                Koketi is one of the best property valuation companies in
                                Ethiopia where we help real estate buyers and
                                sellers get an accurate estimate of the worth of their property.
                                We have an expert team of RICS-qualified
                                chartered surveyors who specialise in property valuation for
                                residential and commercial spaces.
                            </div>
                            <div className="mt-8 max-md:max-w-full">
                                A property valuer is a real estate expert who helps you
                                determine an accurate value for a piece of land or
                                building. Our property valuers offer professional pre-sale or
                                pre-purchase advice to people looking to sell their
                                property or buy new real estate. We have a team of expert
                                property valuers with years of practical experience in
                                the Ethiopia’s real estate market.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col items-center self-stretch max-md:mt-10">
                            <img
                                loading="lazy"
                                srcSet="images/ceo.jpg"
                                className="max-w-full aspect-square w-[285px] rounded-full"
                                alt=""
                            />
                            <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                Eng. Yonas Fantahun
                            </div>
                            <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                    </svg>

                                    <div>Call</div>
                                </a>
                                <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@koketi.com">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                    </svg>

                                    <div>Email</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ServiceProviderMessage;