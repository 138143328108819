import * as React from "react";

interface ImageCardProps {
    imageSrc: string;
    title: string;
    subtitle: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ imageSrc, title, subtitle }) => {
    return (
        <div className="flex flex-col self-stretch justify-center w-full text-center text-white grow bg-neutral-400 max-md:mt-10">
            <div className="flex overflow-hidden relative flex-col justify-center w-full aspect-[0.75]">
                <img loading="lazy" src={imageSrc} alt="" className="absolute inset-0 object-cover size-full" />
                <div className="relative flex flex-col px-12 py-16 bg-sky-950 bg-opacity-70 max-md:px-5">
                    <div className="text-xl">{title}</div>
                    <div className="mt-2.5 mb-24 text-sm font-light leading-4 capitalize max-md:mb-10">{subtitle}</div>
                </div>
            </div>
        </div>
    );
};

export default ImageCard;
