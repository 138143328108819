import * as React from "react";
import { Link } from "react-router-dom";

type Props = {
    icon: React.ComponentType<any>,
    heading: String,
    link: string
}

function ServiceNavBar(props: Props) {
    return (
        <Link to={props.link} className="flex justify-between px-2 py-6 bg-white rounded-lg shadow-lg mb-7 drop-shadow-sm">
            <div className="flex ">

                <props.icon />
                {props.heading}
            </div>
            <svg className="w-7 h-7" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"></path>
            </svg>
        </Link>
    )
}

export default ServiceNavBar;