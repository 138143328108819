import * as React from "react";

interface MyComponentProps {
    title: string;
}

function Title({ title }: MyComponentProps) {
    return (
        <h1 className="mt-16 text-4xl font-bold leading-10 text-black max-md:mt-10 max-md:max-w-full">
            {title}
        </h1>
    );
}

export default Title;