import * as React from "react";
import { Link } from "react-router-dom";

function MiddleFooter() {
  return (
    <div className="flex flex-col justify-center w-full px-16 bg-neutral-700 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-center py-8 mx-10 max-md:flex-wrap max-md:mr-2.5 flex-col lg:flex-row">
        <div className="flex flex-col flex-1 pt-4 text-xs font-light pb-14 text-stone-300">
          <Link to={'/property-evaluation'} className="text-base text-white hover:underline">Property Valuations</Link>
          <div className="mt-5 leading-6">Residential Property Valuation</div>
          <div className="leading-6">Commercial Property Valuation</div>
          <Link className="mt-5 text-base leading-5 text-white hover:underline" to={"/project-managment-and-cost-consultancy"}>
            Project Management & Cost
            <br />
            Consultancy
          </Link>
          <div className="mt-5 leading-6">Project Management</div>
          <div className="leading-6">Commercial Services</div>
          <div className="leading-6">Property Design Management</div>
          <div className="leading-6">Fit-out Management</div>
          <div className="leading-6">Construction Project Management</div>
          <div className="leading-6">MEP Management & Sustainability</div>
          <div className="leading-6">Retail & Design Delivery</div>
        </div>
        <div className="flex flex-col flex-1 pt-4 pb-20 text-xs font-light text-stone-300">
          <Link to={'/property-investment-advisory'} className="text-base text-white hover:underline">
            Property Investment Advisory
          </Link>
          <div className="mt-5 leading-6">
            Real Estate Investment Trust (REITs)
          </div>
          <div className="leading-6">Property Portfolio Diversification</div>
          <div className="leading-6 max-md:mr-2">
            Property Investment Due Diligence
          </div>
          <div className="leading-6">Built-to-Suit Structuring</div>
          <div className="leading-6">Property Debt Advisory</div>
          <div className="leading-6">Technical Due Diligence</div>
          <Link to={'/property-management'} className="mt-5 text-base text-white hover:underline">Property Management</Link>
          <div className="mt-5 leading-6">
            Facilities Management Supervision
          </div>
          <div className="leading-6">MEP Services Supervision</div>
          <div className="leading-6">Soft Services Supervision</div>
        </div>
        <div className="flex flex-col flex-1 justify-end pt-4 pb-2.5 text-xs font-light text-stone-300">
          <Link to={'/building-and-facilities-consultancy'} className="text-base leading-5 text-white hover:underline">
            Building & Facilities
            <br />
            Consultancy
          </Link>
          <div className="mt-5 leading-6">Building & Technical Consultancy</div>
          <div className="leading-6">Facilities Management Consultancy</div>
          <div className="leading-6">Snagging & Handover Services</div>
          <div className="leading-6">RERA Compliance Services</div>
          <div className="leading-6">Property Drone Services</div>
          <Link to={'/industrial-consultancy'} className="mt-5 text-base text-white hover:underline">
            Industrial Consultancy
          </Link>
          <div className="mt-5 leading-6">Asset Valuation</div>
          <div className="leading-6">Asset Register Reconciliation</div>
          <div className="leading-6">Asset Verification</div>
          <div className="leading-6">Asset Coding & Tagging</div>
          <div className="leading-6">Asset Register Compilation & Creation</div>
          <div className="leading-6">Asset Disposal Services</div>
        </div>
        <div className="flex flex-col flex-1 pt-4 pb-6 text-xs font-light text-stone-300">
          <Link to={'/property-strategic-consultancy'} className="text-base text-white hover:underline">
            Property Strategic Consultancy
          </Link>
          <div className="mt-5 leading-6">Market Analysis</div>
          <div className="leading-6">Highest & Best Use Analysis</div>
          <div className="leading-6">Feasibility Studies</div>
          <div className="leading-6">Techno-Economic Viability Studies</div>
          <div className="leading-6">
            Property Investment Financial Assessment
          </div>
          <div className="leading-6">Property Investment Risk Monitoring</div>
          <div className="leading-6">
            Implementation Strategy For Property
            <br />
            Development
          </div>
          <div className="leading-6">Property Location Strategy</div>
          <div className="leading-6">Property Master Planning</div>
          <div className="leading-6">
            Property Investment Entry & Exit
            <br />
            Strategies
          </div>
          <div className="leading-6">Benchmarking Analysis</div>
          <div className="leading-6">Property Lease Advisory</div>
        </div>
        <div className="flex flex-col flex-1 pt-4 pb-16">
          <div className="text-base text-white">Project Marketing</div>
          <div className="mt-5 text-base text-white">
            Attributed and recognized Surveyors
          </div>
          <div className="mt-5 text-base text-white">
            Sales & Leasing Brokerage
          </div>
          <div className="mt-5 text-base text-white">The Firm</div>
          <Link to={'/about-us'} className="mt-5 text-xs font-light leading-6 text-stone-300 hover:underline">
            About Us
          </Link>
          <Link to={'/our-leaders'} className="text-xs font-light leading-6 hover:underline text-stone-300">
            Leadership
          </Link>
          <div className="text-xs font-light leading-6 text-stone-300">
            Careers
          </div>
          <div className="flex flex-wrap content-center gap-2 pr-20 mt-5 max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9ee3e8c3c25be10f39cc4a26e7d0856b6510e0e1abba0d1c6aeb52fcf6d05a8a?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/674660e8240c660a5d8651a9bc7d2a8262a0825b54745311febe69f423903984?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3461da8a4d976a931acb12cfc1359a642820e6edea7d28daae5f53845f1a7463?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e84554a2b14cabddebb842f7b7a051f0036c385b07ac98669e6c798244fab8f1?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
          </div>
          <div className="flex flex-wrap content-center gap-2 pr-20 mt-6 max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/02169ea773990eff2221b1a447ffeb0f7b01ff155f7a3ef33b8f198b9c7bde3a?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/573cade638239d57f55cce62fe262536bf07471f26e2889341c4ed912dc3ffb8?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d4833d1c5ee5b2b221f44d6d5e5ed501f8ea1551b968f748872f3dfd93ebbea5?apiKey=c671582bc9254df89ef0ab1440b5550b&"
              className="w-5 shrink-0 aspect-square"
              alt='d'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MiddleFooter;
