import * as React from "react";
import NavInfoCard from "./NavInfoCard";


function AboutUsNavbar() {



    return (
        <div className="flex justify-center bg-transparent">
            <div className="z-10 grid justify-center w-3/5 grid-cols-3 pb-14 p-11 gap-11 bg-slate-100 ">
                <div className="flex flex-col divide-y divide-black-200 ">

                    <NavInfoCard title={"About Us"} det={"Land Sterling is a leading global real estate solutions provider with a dedicated and qualified team of professionals."} linkTitle={"LEARN MORE"} link={"/about-us"} />
                    <NavInfoCard title={"Leadership"} det={"Get to know Land Sterling leadership and learn more about the individuals who drive our vision."} linkTitle={"MEET oUR KEY PEOPLE"} link={"/our-leaders"} />

                </div>
                <div className="flex flex-col divide-y divide-black-200 ">

                    <NavInfoCard title={"Offices"} det={"Land Sterling is strategically located with our offices in the Middle East, North Africa & Europe. Consult our team now."} linkTitle={"VIEW OUR LOCATIONS"} link={"/offices"} />
                    <NavInfoCard title={"Careers"} det={"Land Sterling offers a unique opportunity to develop your career in a global organization."} linkTitle={"MORE INFORMATION"} link={""} />

                </div>
                <div className="flex flex-col divide-y rounded-lg max-h-max divide-black-200 overflow-clip ">

                    <img className="max-h-max" src="images/slider.jpg" alt="" />
                </div>
            </div>
        </div>
    );
}

export default AboutUsNavbar;