
type Prop = {
    name: string,
    noStars: number,
    testemony: string
}
function TestemonialCard(props: Prop) {

    const ratingStart = [];

    // Iterate over the array
    for (let i = 0; i < props.noStars; i++) {
        // Add the current fruit
        ratingStart.push(<img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6df6c18b03abc207b561dc5a810f54557ee84a32862ec07fe58e5fc7a9cbc45?apiKey=c671582bc9254df89ef0ab1440b5550b&"
            className="w-3 shrink-0 aspect-square"
            alt="star"
        />);
    }
    return (
        <div className="flex flex-col pl-5 ">
            <div className="flex flex-col px-6 py-6 bg-white border-2 border-solid grow rounded-xl border-slate-100">
                <div className="text-lg font-bold leading-7 text-black ">
                    {props.name}
                </div>
                <div className="flex flex-col gap-0 pt-2 pr-20 pb-2 mt-2.5 max-md:pr-5">
                    <div className="flex gap-0 pt-2 pr-20 pb-2 mt-2.5 max-md:pr-5">
                        {ratingStart}
                    </div>

                    <div className="mt-2.5 text-base tracking-wide leading-6 text-zinc-800">
                        {props.testemony}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestemonialCard;